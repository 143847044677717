*{
    padding: 0;
    margin:0;
}
.navbar{
 line-height: 10vh;
 height: 10vh;
 display: flex;
 justify-content: space-between;
 align-items: center;
 background-color: rgb(251, 241, 227);
}

a{
    text-decoration: none;
}

.logo{
   color:rgb(20, 20, 53);
   font-size:20px;
   font-family: Lucida Handwriting;
   margin: 20px;
}
li {
    
    list-style: none;
}
.links{
    display:flex;
    justify-content: flex-end;
    width: 75%;

}
.links  li{
    padding: 0 10px;
    margin: 0 10px;
    font-size: 15px ;
    color: rgb(75, 4, 4);
    text-transform: capitalize;
    font-family: Segoe UI;
    font-weight: bold;

}




button{
    border:none;
font-size: 20px;
background: none;
}

.mobile{
    display: none;
    cursor: pointer;
}

@media (max-width:768px) {
   
    .links{
        display:none;
    }
    .mobile-links{
        position: absolute;
        display: block;
        list-style: none;
        box-shadow: rgba(50, 50, 93 ,0.23) 10px 30px 30px -30px;
        left: 0;
        top:10%;
        transition:all 0.5s ease-in-out;
        width: 100%;
        background-color: white;
    }
    ul li{
        padding:32px;
        width: 100%;
        transition:all 0.5s ease-in-out;
        text-align: center;
    }
    .mobile{
        position:absolute;
        display: block;
        right:10px;
    }
}